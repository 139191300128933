(function ($) {
    var nav = $('#js-main-header')

    $(window).scroll(function() {
        var scroll = $(window).scrollTop()

        if (scroll >= 20) { 
            nav.addClass('main-header--scroll')
        }
        else { 
            nav.removeClass('main-header--scroll')
        }
    })

 })(jQuery);