(function ($) {
    
    var $slider = $('#js-home-events-slider')

    $slider.slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: '<div class="slider-arrow slider-arrow--home-prev"><span class="slider-arrow__icon icon-angel-left"></span></div>',
        nextArrow: '<div class="slider-arrow slider-arrow--home-next"><span class="slider-arrow__icon icon-angel-right"></span></div>',

        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });
 
 })(jQuery);