(function ($) {
   
    $(window).on("load", function() {
        var s = skrollr.init({
            forceHeight: false,
        });
        s.refresh();

        if (s.isMobile()) {
            s.destroy();
        }
    });

 })(jQuery);