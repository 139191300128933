(function ($) {

    var $modalId = $('#image-gallery'),
        $prevLink = $('#js-show-prev-image'),
        $nextLink = $('#js-show-next-image'),
        $galleryImg = $('#js-image-gallery-image')

    $(document).ready(function () {
        loadGallery($('.js-thumbnail'))
    
        //This function disables buttons when needed
        function disableButtons(counter_max, counter_current) {
            $prevLink.show()
            $nextLink.show()
            if (counter_max === counter_current) {
                $nextLink.hide()
            } 
            else if (counter_current === 1) {
                $prevLink.hide()
            }
        }
    
        /**
         *
         * @param setClickAttr  Sets the attribute for the click handler.
         */
    
        function loadGallery(setClickAttr) {
            let current_image,
                selector,
                counter = 0,
                relation = ''

            $prevLink.click(function() {
                current_image--
                selector = $('[rel="' + relation + '"][data-image-id="' + current_image + '"]')
                updateGallery(selector)
            })

            $nextLink.click(function() {
                current_image++
                selector = $('[rel="' + relation + '"][data-image-id="' + current_image + '"]')
                updateGallery(selector)
            })
    
            function updateGallery(selector) {
                let $sel = selector
                current_image = $sel.attr('data-image-id')
                relation = $sel.attr('rel')
                $galleryImg.attr('src', $sel.data('image'))
                disableButtons(counter, $sel.data('image-id'))
            }
    
            $(setClickAttr).on('click', function () {
                counter = $('[rel="' + $(this).attr('rel') + '"]').length
                updateGallery($(this))
            });
        }
    });
    
    /**
     * keyboard navigation
     */
    $(document).keydown(function (e) {
        switch (e.which) {
            case 37: // left
                if (($modalId.data('bs.modal') || {})._isShown && $prevLink.is(":visible")) {
                    $prevLink.click()
                }
                break
    
            case 39: // right
                if (($modalId.data('bs.modal') || {})._isShown && $nextLink.is(":visible")) {
                    $nextLink.click()
                }
            break
    
            default:
                return // exit this handler for other keys
        }
        e.preventDefault()
    });
 
 })(jQuery);