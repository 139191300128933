(function ($) {
    
    var $form = $('#js-contact-data'),
        $results = $('#js-contact-data-results'),
        $container = $('#js-section-home-contact')

    $form.on('change', function() {
        if ($form.find('#js-localization').val()) {
            _ajaxLoad($form.serialize())
        }
    })

    function _ajaxLoad(settings) {
        $.ajax({
            url:ajax.url,
            data: {
                action: 'load_contact',
                settings: settings,
            },
            type:'post',
            beforeSend: function() {
                $container.addClass('loading')
            },
            success:function(data){
                $results.html(data)
                $container.removeClass('loading')
            },
            error:function(data){
                $container.removeClass('loading')
            }
        });
        return false;
    }
    
 
 })(jQuery);