(function ($) {
    
    var $slider = $('#js-product-slider'),
        $sliderNav = $('.js-product-slider-nav')

    $slider.slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $sliderNav.each(function() {
            var target = $(this).attr('data-target')
            $(this).removeClass('active')
            if (target == nextSlide) {
                $(this).addClass('active')
            }
        })
    })

    $sliderNav.on('click', function() {
        $slider.slick('goTo', $(this).attr('data-target'))
    })


 
 })(jQuery);